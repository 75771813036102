<template>
  <PageLayout>
    <template #header>
      <k-title>
        <slot name="header"/>
      </k-title>
    </template>
    <template #flow-actions>
      <slot name="flow-actions"/>
    </template>
    <template #default>
      <slot :attrs="{value: isFilterbarOpen, filters}"
            :on="{ 'update:filters': handleFiltersUpdate, input: handleFilterInput }"
            name="filters"/>

      <v-row align="center" class="mt-8" no-gutters>
        <v-col v-if="withSearch || withFilters" class="searchbar">
          <v-row align="center" no-gutters>
            <v-form v-if="withSearch" @submit.prevent="performSearch">
              <VTextField v-model="searchQuery"
                          dense
                          filled
                          background-color="white"
                          class="mr-2"
                          hide-details
                          :placeholder="$t('actions.search')"/>
            </v-form>
            <v-btn v-if="withSearch"
                   height="34"
                   @click="performSearch"
                   tile
                   outlined
                   color="grey lighten-1"
                   width="62"
                   class="mr-2">
              <v-icon color="grey darken-1">$search</v-icon>
            </v-btn>
            <v-btn v-if="withFilters"
                   height="34"
                   tile
                   outlined
                   color="grey lighten-1"
                   width="62"
                   @click="toggleFilterbar">
              <v-badge :value="hasFilters" color="info" dot>
                <v-icon color="grey darken-1">$filter</v-icon>
              </v-badge>
            </v-btn>
          </v-row>
        </v-col>
        <VSpacer/>
        <v-btn-toggle v-if="viewSlots.length > 1" v-model="view" @change="handleViewChange">
          <v-btn v-for="slot in viewSlots" :key="slot" icon>
            <v-icon color="grey darken-1">{{ viewIcons[slot.substr(5)] || '$viewList' }}</v-icon>
          </v-btn>
        </v-btn-toggle>
      </v-row>

      <slot name="main-prepend"></slot>

      <div class="mt-12">
        <v-sheet :color="sheetColor || 'transparent'">
          <slot name="sheet-prepend"/>
          <v-window v-model="view">
            <v-window-item v-for="slot in viewSlots" :key="slot">
              <slot :name="slot"/>
            </v-window-item>
          </v-window>
        </v-sheet>
      </div>
    </template>
  </PageLayout>
</template>

<script>
import KTitle from '@/components/layout/KTitle.vue';
import PageLayout from '@/components/layout/PageLayout.vue';
import { mapGetters, mapMutations } from 'vuex';

export default {
  name: 'KCrudLayout',
  components: {
    KTitle,
    PageLayout,
  },
  data: () => ({
    view: 0,
    searchQuery: '',
    isFilterbarOpen: false,
  }),
  props: {
    search: {
      type: String,
      default: '',
    },
    filters: {
      type: Object,
      default: () => ({}),
    },
    viewIcons: {
      type: Object,
      default: () => ({}),
    },
    withSearch: {
      type: Boolean,
      default: true,
    },
    sheetColor: {
      type: String,
      default: 'white',
    },
  },
  computed: {
    viewSlots() {
      return Object.keys(this.$slots).filter((slot) => slot.substr(0, 5) === 'view.');
    },
    withFilters() {
      return this.$scopedSlots.filters;
    },
    hasFilters() {
      return Object.values(this.filters).some(val => !this.isEmpty(val));
    },
  },
  created() {
    this.view = this.getSelectedCrudLayoutView()(this.$route.name);
  },
  methods: {
    ...mapMutations('settings', ['setCrudLayoutView']),
    ...mapGetters('settings', ['getSelectedCrudLayoutView']),
    performSearch() {
      this.$emit('update:search', this.searchQuery);
    },
    handleViewChange(view) {
      this.setCrudLayoutView({
        routeName: this.$route.name,
        selectedView: view,
      });
    },
    toggleFilterbar(to = null) {
      this.isFilterbarOpen = to === null ? !this.isFilterbarOpen : to;
    },
    handleFiltersUpdate(event) {
      return this.$emit('update:filters', event);
    },
    handleFilterInput(event) {
      return this.toggleFilterbar(event);
    },
    // @TODO: refactor to use the isEmpty util
    isEmpty(val) {
      if (val === null) return true;
      if (typeof val === 'object') return Object.values(val).every((nested) => this.isEmpty(nested));
      if (Array.isArray(val)) return val.length === 0;
      return !val;
    },
  },
};
</script>

<style scoped lang="scss">
.searchbar {
  width: 350px;
}

::v-deep {
  .v-text-field {
    border-radius: 0;

    > .v-input__control > .v-input__slot:before, .v-text-field > .v-input__control > .v-input__slot:after {
      bottom: 0;
    }
  }
}
</style>
